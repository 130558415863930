<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.business_units')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('config.business_units.add_business_unit') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="businessUnitRooms" @deleteRow="remove" @editRow="editRow" @edit="edit" @confirm="confirmBusinessUnit"/>
        </b-card>
        <AddBusinessUnit ref="addModal" v-on:itemAdded="loadData" :warehouses="warehouses"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddBusinessUnit from '@/views/BusinessUnits/AddBusinessUnit'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        mixins: [itemChange],
        components: {Table, AddBusinessUnit,  BCard, BOverlay, BButton},
        props: {
            buttonType: {
                type: String,
                default: 'Confirm'
            }
        },
        computed: {
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)

                const defs = [
                    { headerName: this.$t('table_fields.business_unit_code'), field: 'business_unit_code', filter: true, editable: false},
                    { headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: false},
                    { headerName: this.$t('table_fields.address'), field: 'street', filter: true, cellRenderer: (params) => this.getFullstreet(params), editable: false}
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: {button: this.buttonType}}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                businessUnitRooms: [],
                warehouses:[],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnitRooms = response.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadWarehouses() {
                if (!this.$hasTierPermission(Tiers.Standard, Tiers.Premium)) return

                try {
                    const response = await this.$http.get('/api/client/v1/warehouses/')
                    this.warehouses = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(err.response)
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true
                    
                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    
                    await this.$http.put(`/api/client/v1/business_units/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async confirmBusinessUnit(id) {
                try {
                    this.showLoader = true

                    await this.$http.put(`/api/client/v1/business_units/${id}/confirm`)
                    this.$printSuccess(this.$t('print.success.confirmed'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            getFullstreet(params) {
                if (!(params && params.data)) {
                    return '/'
                } else if (params.data.business_unit_type !== '') {
                    return '/'
                }
                
                return `${params.data.street  } ${  params.data.house_number  } ${  params.data.house_number_additional  }, ${  params.data.postal_code} ${  params.data.city }`
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/business_units/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getTables(value) {
                if (value && value.length > 0) return value.map(option => { return `${option.name}` }).join(', ')
                else return '/'
            }
        },
        mounted() {
            this.loadData()
            this.loadWarehouses()
        }
    }
</script>

<style scoped>

</style>
